import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enLocales from "./locales/en.json";
import viLocales from "./locales/vi.json";

i18n.use(initReactI18next).init({
  resources: {
    en: { translations: enLocales },
    vi: { translations: viLocales },
  },
  lng: localStorage.getItem("i18nextLng") || "en",
  fallbackLng: "en",
  debug: false,
  ns: ["translations"],
  defaultNS: "translations",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
