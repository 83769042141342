import { AppLayout } from "layouts/app";
import { MainLayout } from "layouts/main";
import { lazy, Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";

const FallbackLoading: React.FC = () => {
  // TODO: implement
  return <div />;
};

const Loadable = (Component: any) => (props: any) => {
  return (
    <Suspense fallback={<FallbackLoading />}>
      <Component {...props} />
    </Suspense>
  );
};

const HomePage = Loadable(lazy(() => import("./pages/Home")));

const LiveLocationPage = Loadable(lazy(() => import("./pages/LiveLocation")));

const NotFoundPage = Loadable(lazy(() => import("./pages/NotFound")));

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "",
        index: true,
        element: <HomePage />,
      },
    ],
  },
  {
    path: "/live",
    element: <AppLayout />,
    children: [
      {
        path: "",
        index: true,
        element: <LiveLocationPage />,
      },
    ],
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
]);

export default router;
